import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from "../assets/lectora.png"
import boys from "../assets/boys.png"
import "./FirstPage.css"



export default function FirstPage() {

    return (
        <div className="container_pg">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='divCenter'>
                

            <img
                    src={logo}
                    width="700"
                    height="500"
                    className="img-fluid d-block mx-auto"
                    alt='logoMingga'
                    />
                            


                {/* <h3 id="titulo1_" > <b><i>
                CENTRO EDUCATIVO CATALINA</i></b></h3>
                <br /> */}

                    <h5 id="titulo1_">PROGRAMA DE FOMENTO <br />
                        de 
                        la Lectura y el multilingüismo
                    </h5>
                <br />
                <img
                    src={boys}
                    width="400"
                    height="300"
                    className="img-fluid d-block mx-auto"
                    alt='logoMingga'
                    />
                <h2 id="titulo1_"> <b>Vamos a leer!! </b></h2>
                <br />

                <h5 id="titulo1_" > <b><i> Para construir entre todos
                <br />
                Una Colombia más educada y culta</i></b> </h5>
                
                <br />
                <Link         
                className="btn btn-success" id="seguir_1" to="/SecondPage"><b>Seguir </b> 
                </Link>
                <br />
                

            </div>
        </div>
    )
}