import React from 'react'
import { Link } from 'react-router-dom'
import gobernador from "../assets/Gobernador.jpg"
import "./SecondPage.css"


export default function SecondPage() {

    return (
        <div className="container_pg">
        <br/>
            <div className='divCenter2'>
                {/* <h4 id="titulo1_"> <b><i>  CENTRO EDUCATIVO CATALINA <br />
                    </i></b></h4> */}

                    <img
                            src={gobernador}
                            width="180"
                            height="180"
                            className="img-fluid d-block mx-auto"
                            alt='logoMingga'
                            />
                            


                <h5 id="titulo1_" > <b><i> HENRY ROMERO TRUJILLO
                <br />
                Director</i></b> </h5>
                <br />


                <h6 id="titulo1_" className="container_form"> La lectura es uno de los hábitos más importantes para las personas: es la puerta de acceso al conocimiento en las diversas áreas, y al patrimonio literario nacional y universal. Permite mejorar el nivel educativo y cultural de la sociedad, y fortalece las competencias comunicativas, claves para tener éxito en la moderna sociedad de la información y las comunicaciones integrada a nivel orbital.
                    <br />
                    <br />
                    Por eso hemos diseñado el Plan Nacional de Lectura “LEO LA VIDA”, con más de 15.000 obras gratuitas en 5 idiomas, para mejorar los indicadores de lectura, con la meta de que los colombianos leamos 1 libro al mes, 12 libros al año.
                </h6>
                <br />
                
                <h5 id="titulo1_" > <b><i>
                Colombia, un país de lectores</i></b> </h5>
                <br />


                
                <br />
                <Link         
                className="btn btn-success" id="seguir_1" to="/pageFilters"><b>Seguir </b> 
                </Link>
                <br />
                

            </div>
        </div>
    )
}