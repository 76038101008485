import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import Gobernador from "../../assets/Gobernador.jpg";
import "./SingIn.css"
import { booksCatalogoAction, booksCopyAction, booksInfo } from '../../stateManagement/actions/booksInfoAction';
import { getBooks } from '../../services/bookServices';
import { booksAuthors, booksCountries, booksGenres } from '../../stateManagement/actions/stateActions';
import { getAuthors, getCountries, getGenres } from '../../services/dataByLanguage';
import { recomendedAction } from '../../stateManagement/actions/recommendedAction';
import { changeLanguageAction } from '../../stateManagement/actions/changeLanguageAction';
import { ToastContainer, toast } from 'react-toastify';
import niños from "../../assets/niños.png";
import loggo from "../../assets/loggo.png";


import Modal from '../../components/Modal';
import { infoToken, infoUser } from '../../stateManagement/actions/infoUserAction';
import { postLogin, recoverPass } from '../../services/userServices';
import styled from 'styled-components';

const initialState = {
    email: "",
    password: "",
};

export default function SignIn() {

    const dispatch = useDispatch()

    const demo = false

    // const charge = async () =>{
    //     dispatch(changeLanguageAction(1))
    //     const books = await getBooks(1)
    //     if(books?.length>0){
    //         // const recommend = books.filter(r => r.isFree===true)
    //         // dispatch(recomendedAction(recommend))
    //         if(demo){
    //             books.sort((x, y) => x.name.localeCompare(y.name))
    //             const demoBooks = books.slice(0,100)
    //             // dispatch(booksInfo(demoBooks))
    //             // dispatch(booksCatalogoAction(demoBooks))
    //             dispatch(booksCopyAction(demoBooks))
    //             // dispatch(recomendedAction(recommend))
    //         }
    //         else {
    //             // dispatch(booksInfo(books))
    //             // dispatch(booksCatalogoAction(books))
    //             dispatch(booksCopyAction(books))
    //             // dispatch(recomendedAction(recommend))
    //         }
    //     }
    // // const [genres, countries, authors] = await Promise.all([
    // //     getGenres(1),
    // //     getCountries(1),
    // //     getAuthors(1),
    // // ]
    // // )
    // // dispatch(booksGenres(genres))
    // // dispatch(booksCountries(countries))
    // // dispatch(booksAuthors(authors))
    // }

    // useEffect(()=> {// eslint-disable-next-line react-hooks/exhaustive-deps
    //     charge();}, [])
    const navigate = useNavigate()

    const [input, setInput] = useState(initialState)
    const [errors, setErrors] = useState(initialState);
    const [modalUpdate, setModalUpdate] = useState(false)
    const [recoveryPass, setRecoveryPass] = useState({})

    function handleInputChange(input, e){
        e.preventDefault()
        setInput(prev=>({...prev, [input]:e.target.value}))
    }

    const notify = () => {
        toast('Correo enviado', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };

    const notifyError = () => {
        toast('Usuario no existe', {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
    };

    function handleRecovery(e){
        e.preventDefault()
        // setInput(prev=>({...prev, [input]:e.target.value}))
        setRecoveryPass(e.target.value)
    }

    const onSend = async (e) => {
        e.preventDefault()
        const error = {};
        if (!input.password) { error.password = "Requerido" };
        if (!input.email) { error.email = "Requerido" };
        if (!(Object.entries(error).length === 0)) { setErrors(error) }
        else {
            const user = await postLogin(input);
            console.log(user);
            if (user.isAuthenticated) {
                if(user.user.RoleId===4 || user.user.RoleId===3){
                    if(user.user.isSuscribed){
                        user.user.name=user?.user?.name?.reverse().join(" ")
                        dispatch(infoUser(user.user))
                        dispatch(infoToken(user.token))
                        navigate('/pageFilters')
                    }
                    else {
                        const obj ={
                            email:user.user.email,
                            password:input.password
                        }
                        navigate('/paid', {state:obj})
                    }
                }
                else{
                    setErrors(prev=>({...prev, email:"Correo o Password incorrecto"}))
                    setErrors(prev=>({...prev, password:"Correo o Password incorrecto"}))
                }
            }
            else if(user==="Username or password incorrect"){
                setErrors(prev=>({...prev, email:"Correo o Password incorrecto"}))
                setErrors(prev=>({...prev, password:"Correo o Password incorrecto"}))
            }
        }
    };
    async function recovery(){
        const recover = await recoverPass(recoveryPass)
        if(recover.message==="Error sending mail"){
            notifyError()
        }
        if(recover.message==="mail send"){
            console.log("ingresa recovery");
            notify()
            setModalUpdate(!modalUpdate)
        }
    }

    return (
        <div id='paginasecundaria'>
            <div>
                <br />

                
                <img
                        src={loggo}
                        width="300"
                        height="200"
                        className="img-fluid d-block mx-auto"
                        alt='logoMingga'
                        />

                <br />
            

            <h3 id="titulo1_"> <b> <i>Bienvenidos al mágico mundo de la lectura</i> </b></h3>

                <img
                        src={niños}
                        width="350"
                        height="400"
                        className="img-fluid d-block mx-auto"
                        alt='logoMingga'
                        />


            <h5 id="titulo1_" > <b><i> La puerta de acceso al conocimiento <br />
            y al patrimonio literario de la humanidad
             </i></b> </h5>

                <div className="container-fluid" id="registro">
                    <div className="container">
                        <div className="container text-center">
                        </div>
                        <br />

                        <h4 id="titulo1_">Iniciar sesión</h4>
                            {/* {(language===1 || languageChange===1)&&<h2><b>Bienvenido a ClubLeo</b> </h2>}
                            {(language===2 || languageChange===2)&&<h2><b>welcome to ClubLeo</b> </h2>}
                            {(language===3 || languageChange===3)&&<h2><b>Bienvenue à ClubLeo</b> </h2>}
                            {(language===4 || languageChange===4)&&<h2><b>bem-vindo a ClubLeo</b> </h2>}
                            {(language===5 || languageChange===5)&&<h2><b>benvenuto aClubLeo</b> </h2>}
                            
                            {(language===1 || languageChange===1)&&<label className="l-01"> <h4>Iniciar sesión</h4></label>}
                            {(language===2 || languageChange===2)&&<label className="l-01"> <h4>Log in</h4></label>}
                            {(language===3 || languageChange===3)&&<label className="l-01"> <h4>Commencer la session</h4></label>}
                            {(language===4 || languageChange===4)&&<label className="l-01"> <h4>Iniciar sessão</h4></label>}
                            {(language===5 || languageChange===5)&&<label className="l-01"> <h4>Login</h4></label>} */}
                            

                            <div  className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">Ingresa con tu correo</span>

                                <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                onChange={(e)=>handleInputChange("email", e)} required/>
                                
                                {/* {(language===1 || languageChange===1)&&<label >Ingresa con tu Usuario</label>}
                                {(language===2 || languageChange===2)&&<label >Login with your User</label>}
                                {(language===3 || languageChange===3)&&<label >Connectez-vous avec votre utilisateur</label>}
                                {(language===4 || languageChange===4)&&<label >Faça login com seu usuário</label>}
                                {(language===5 || languageChange===5)&&<label >Accedi con il tuo Utente</label>} */}
                            </div>
                            {errors.email ? <span className='textError'>{errors.email}</span> : <></>}

                            <div  className="input-group mb-3">
                            <span className="input-group-text" id="inputGroup-sizing-default">Ingresa con tu contraseña</span>

                                <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default"
                                onChange={(e)=>handleInputChange("password", e)} required/>
                                <br />
                                </div>

                                <Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p id="titulo1_"><b>¿Olvidaste tu contraseña?</b></p></Link>
                                <Link to="/SignUp" className="password"> <p id="titulo1_"><b>¿Aún no te has registrado?</b></p></Link>
                                {/* {(language===1 || languageChange===1)&&<label >Ingresa tu contraseña</label>}
                                {(language===2 || languageChange===2)&&<label >Enter your password</label>}
                                {(language===3 || languageChange===3)&&<label >Tapez votre mot de passe</label>}
                                {(language===4 || languageChange===4)&&<label >Coloque sua senha</label>}
                                {(language===5 || languageChange===5)&&<label >Inserisci la tua password</label>}

                                {(language===1 || languageChange===1)&&<Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p><b>¿Olvidaste tu contraseña?</b></p></Link>}
                                {(language===2 || languageChange===2)&&<Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p><b>Did you forget your password?</b></p></Link>}
                                {(language===3 || languageChange===3)&&<Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p><b>Vous avez oublié votre mot de passe?</b></p></Link>}
                                {(language===4 || languageChange===4)&&<Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p><b>Você esqueceu sua senha?</b></p></Link>}
                                {(language===5 || languageChange===5)&&<Link href="/" className="password" onClick={()=>setModalUpdate(!modalUpdate)}> <p><b>Hai dimenticato la tua password?</b></p></Link>} */}

                            
                            <p className="text">
                            {/* {(language===1 || languageChange===1)&&<b>Mantén segura tu identidad corporativa.
                            El usuario y contraseña son personales e intransferibles.
                            </b>}
                            {(language===2 || languageChange===2)&&<b>Keep your corporate identity secure.
                                The username and password are personal and non-transferable.
                                </b>}
                            {(language===3 || languageChange===3)&&<b>Protégez votre identité d’entreprise.
                                L'identifiant et le mot de passe sont personnels et incessibles.
                                </b>}
                            {(language===4 || languageChange===4)&&<b>Mantenha sua identidade corporativa segura.
                                O nome de usuário e a senha são pessoais e intransferíveis.
                                </b>}
                            {(language===5 || languageChange===5)&&<b>Mantieni sicura la tua identità aziendale.
                                Il nome utente e la password sono personali e non trasferibili.
                                </b>} */}
                            </p>

                            <Link         
                            className="btn btn-success" id="seguir_1" onClick={onSend}><b>Ingresar </b> 
                            </Link>
                            <br />
                            <div className="d-grid gap-2 col-6 mx-auto">
                        </div>
                    </div>
            </div>

        </div>
        <Modal
                estado={modalUpdate}
                cambiarEstado={setModalUpdate}
            >
            <Contenido>
                <h1>Recuperar contraseña</h1>
                <div className="form-floating mb-3">
                        <input 
                        className="form-control"
                        onChange={(e)=>handleRecovery(e)}/>
                        <label htmlFor="floatingInput">Correo de usuario</label>
                    </div>
                <div className='row'>
                    <Boton onClick={()=>recovery()}>Enviar un correo</Boton>
                </div>
            </Contenido>
        </Modal>
    </div>
    )
}


const Boton = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #1766DC;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;

	&:hover {
		background: #0066FF;
	}
`;

const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	p {
		font-size: 18px;
		margin-bottom: 20px;
	}

	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
	}
`;